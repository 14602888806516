.projeto-pandemia-sem-fome{
    width: 100%;
    min-height: 150px;
    height: 25vw;
    background-repeat: no-repeat;
    background-size: cover;
}

.shadowBoxProjetos{
    width: 100%;
    height: 100%;
    background-color: rgba(242, 46, 46, 1);
    transition: 0.3s;
}

.shadowBoxProjetos:hover{
    width: 100%;
    height: 100%;
    background-color: rgba(242, 46, 46, .4);
}

.texto-projeto-pandemia-sem-fome{
    margin-left: 6vw;
    padding-top: 12vw;
}

.outros-projetos{
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% - 2px);
    min-height: 100px;
    height: 20vw;
}

.texto-projetos{
    text-align: center;
    padding-top: 8vw;
}