.borderProjetosCredenciados {
    border: 5px solid #6BBF71;
    border-radius: 10px;
}

.linkSaibaMais {
    margin-top: 3vh;
    font-size: max(0.9vw, 11px);
    font-weight: 700;
    color: var(--black);
    cursor: pointer;
    user-select: none;
}

.textoProjetoAssociado {
    font-size: max(0.9vw, 13px);
    font-weight: 400;
    color: var(--black);
}