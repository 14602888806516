.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox > span {
    line-height: 2px;
}

.checkbox > input {
    height: 10px;
    width: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 2px solid var(--black);
    outline: none;
  }

.checkbox > input:checked {
    background-color: #34495E;
}

.checkbox > input:checked + span::before {
    display: block;
    text-align: center;
    color: #41B883;
    position: absolute;
    left: 0.35rem;
    top: 0.65rem;
}

.text-checkbox {
    font-size: max(10px, 0.7vw);
    font-weight: 600;
}
