.slideImage{
    background-repeat: no-repeat;
    background-size: cover;
}

.shadoBoxSlideImage{
    width: 100%;
    height: 100%;
}

.container-slide-images{
    position: relative;
}

.prevArrow{
    position: absolute;
    z-index: 1000;
    /* margin-top: 45vh; */
    margin-left: 3vw;
    cursor: pointer;
}
.nextArrow{
    position: absolute;
    z-index: 1000;
    /* margin-top: 45vh; */
    cursor: pointer;
    margin-left: calc(95vw - 40px);
}

.boxTextoSlides{
    padding-left: 10vw;
    padding-right: 10vw;
}

.centerText{
    text-align: center;
    
}