.backgroundTituloPorqueAjudar {
    background-color: var(--blue);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    padding: 4vh 0px 4vh 0px;
    height: 50%;
    width: 100%;
}

.tituloPorqueAjudar {
    margin-top: 2vh;
    text-align: center;
    font-size: max(1.8vw, 18px);
    font-weight: 900;
    color: var(--rose);
}

.subtituloPorqueAjudar {
    margin-top: 10px;
    text-align: center;
    font-size: max(1.2vw, 13px);
    font-weight: 600;
    color: white;
}

.backgroundTextoPorqueAjudar {
    background-color: var(--buttonColor);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
    padding: 2vh 0px 2vh 0px;
    height: 45%;
    width: 100%;
}

.textoPorqueAjudar {
    color: white;
    text-align: center;
    margin-top: 4vh;
}