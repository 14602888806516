#float-label {
    display: flex;
}

#float-label input {
    width: 100%;
    /* height: 74px; */
    padding: 14px 16px 0 10px;
    outline: 0;
    color: #8B8989, 100%;
    background: #fff;
    font-size: max(10px, 1vw);
    border: 2px solid #C4C4C4;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

#float-label label {
    font-size: max(10px, 1vw);
    font-weight: 500;
    padding: 0 12px;
    color: #8B8989, 100%;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 12px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
}