.backgroundPasssoComoFunciona {
    background-color: var(--rose);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2vh 0px 2vh 0px;
    height: 100%;
    width: 100%;
}

.dotComoFunciona {
    width: max(30px, 3vw);
    height: max(30px, 3vw);
    background: var(--buttonColor);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    text-align: center;

    line-height: max(30px, 3vw);
    font-size: max(1.7vw, 13px);
    font-weight: 800;
    color: var(--black);
}

.tituloComoFunciona {
    margin-top: 2vh;
    text-align: center;
    font-size: max(1.2vw, 12px);
    font-weight: 700;
    color: var(--black);
}

.textoComoFunciona{
    margin-top: 2vh;
    text-align: justify;
    font-size: max(1vw, 11px);
    font-weight: 600;
    color: var(--black);
}