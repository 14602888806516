.backgroundMetaPalas{
    height: fit-content;
    background-color: var(--yellowPalas);
    padding: 2vh 0px 5vh 0px;
}

.metaArrecadacaoTexto{
    color: var(--red);
    font-weight: 900;
    font-size: max(1vw, 14px);
}

.textoValorArrecadacao{
    color: white;
    font-weight: 900;
    font-size: max(2.3vw, 18px);
}