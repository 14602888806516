.pixBackground{
    background-color: var(--red);
    padding-top: 5vw;
    padding-bottom: 5vw;
    background-image: url("../../../assets/palas.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.qrcodeBack{
    background: #FBFBFB;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: max(1.5vw, 1.5vh);
}

.spanAlignCenter {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }