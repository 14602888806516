.dotImageSelectorActive{
    background: white;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
}

.dotImageSelectorUnactive{
    width: 10px;
    height: 10px;
    background: var(--grey);
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
}