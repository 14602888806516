.action-button-box{
    text-align: center;
    color: white;
    background-color: var(--buttonColor);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    line-height: 8px;
}

.action-button-box-form{
    text-align: center;
    color: white;
    background-color: var(--buttonColor);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    /* padding:12px 10px 12px 10px; */
    cursor: pointer;
    font-size: max(12px, 1vw);
    font-weight: 900;
}

.action-button-box-form:hover{
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}

.action-button-box:hover{
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}